import React from 'react';
import { Helmet } from 'react-helmet';
import ProductSearch from './ProductSearch';
import NavMenu from './NavMenu';
import Footer from './Footer';
import './css/App.css';

const App = () => {
    return (
    <div>
      <Helmet>
        <title>استعلام اصالت - صنایع برودتی میلاد</title>
        <meta
          name="description"
          content="استعلام اصالت محصولات صنایع برودتی میلاد. بررسی گارانتی و اطلاعات محصول."
        />
        <meta charSet="UTF-8" />
        <meta name="keywords" content="استعلام اصالت, صنایع برودتی میلاد, گارانتی, محصولات برودتی" />
        <meta name="author" content="Milad cooling Industries" />
        <meta name="viewport" content="width=device-width,minimum-scale=1.0, maximum-scale=1.0"/>
        <meta property="og:locale" content="fa_IR" />
        <meta property="og:type" content="website" />
        <meta name="msapplication-TileColor" content="#1f1f22"/>
        <link rel="icon" type="image/x-icon" href="https://miladcoolingindustry.com/favicons/favicon-favicon-32x32.png"/>
      </Helmet>
      <NavMenu />
      <div className='searchText'>
        <h2 className="headerText">استعلام اصالت </h2>
        <h3 className='miladText'>صنایع برودتی میلاد</h3>
      </div>
      <ProductSearch />
      <Footer />
    </div>
  );
};

export default App;
