import React, { useState } from 'react';
import './css/NavMenu.css'; // Custom CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const NavMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <nav className="nav-menu">
      <div className="nav-header">
        <div className="logo">Milad Cooling Industry</div>
        <FontAwesomeIcon
          icon={isMenuOpen ? faTimes : faBars}
          className="menu-toggle"
          onClick={toggleMenu}
        />
      </div>
      <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <li>
          <a href="https://miladcoolingindustry.com/">صفحه اصلی</a>
        </li>
        <li>
          <a href="#about">درباره ما</a>
        </li>
        <li>
          <a href="https://miladcoolingindustry.com/#portfolioIntro">محصولات</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
