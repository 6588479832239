import React from 'react';
import './css/Footer.css'; // Custom CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p> کلیه حقوق مادی و معنوی این سایت محفوظ است &copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
