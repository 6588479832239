import jalaali from 'jalaali-js';

/**
 * Converts a Gregorian date to a Solar Hijri (Jalali) date.
 * @param {string} gregorianDate - The Gregorian date in the format YYYY-MM-DD.
 * @returns {string} - The Solar Hijri date in the format YYYY-MM-DD.
 */
export const convertToSolar = (gregorianDate) => {
  const [year, month, day] = gregorianDate.split('-').map(Number);
  const jalaaliDate = jalaali.toJalaali(year, month, day);
  return `${jalaaliDate.jy}-${String(jalaaliDate.jm).padStart(2, '0')}-${String(jalaaliDate.jd).padStart(2, '0')}`;
};

/**
 * Converts a Solar Hijri (Jalali) date to a Gregorian date.
 * @param {string} solarDate - The Solar Hijri date in the format YYYY-MM-DD.
 * @returns {string} - The Gregorian date in the format YYYY-MM-DD.
 */
export const convertToGregorian = (solarDate) => {
  const [year, month, day] = solarDate.split('-').map(Number);
  const gregorianDate = jalaali.toGregorian(year, month, day);
  return `${gregorianDate.gy}-${String(gregorianDate.gm).padStart(2, '0')}-${String(gregorianDate.gd).padStart(2, '0')}`;
};
